if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) {
            return null;
        }
        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement;
        } while (el !== null);
        return null;
    };
}

(function($, mdc, LazyLoad) {

    var UTIL, Collection = {};
    var Sage = {
        // All pages
        'common': {
            init: function() {
                Sage.activateMdcSelects();
                Sage.activateMdcButtons();
                Sage.activateMdcFields();
                Sage.activateInputFiles();
                Sage.offcanvasMenu();
                Sage.fixedHeader();
                Sage.searchBox();
                Sage.enableLazyLoad();
            },

            finalize: function() {}
        },

        'single': {
            init: function () {
                Sage.single.enableShare();
            },

            enableShare: function () {
                var btnShareOpen = document.querySelector('.btn-share-open');
                var shareWrapper = document.querySelector('.entry-share');
                var isOpen = false;

                if (!btnShareOpen) {
                    return;
                }

                btnShareOpen.addEventListener('click', function () {
                    if (isOpen) {
                        shareWrapper.classList.remove('is-open');
                    } else {
                        shareWrapper.classList.add('is-open');
                    }

                    isOpen = !isOpen;
                });
            }
        },

        'home': {
            init: function() {},
            finalize: function() {}
        },

        'contato': {
            init: function() {}
        },

        'acervo': {
            init: function () {
                Collection.init();
            },
            updateFilter: function () {
                Collection.updateFilter();
            }
        },

        activateMdcSelects: function () {
            var selectsManagers = document.querySelectorAll('.select-manager');

            if (window.mdc === undefined || !selectsManagers.length) {
                return false;
            }

            var MDCSelect = mdc.select.MDCSelect;

            UTIL.forEach(selectsManagers, function (index, HTMLElement) {
                var custom = MDCSelect.attachTo(HTMLElement.querySelector('.mdc-select[role="listbox"]'));
                var native = HTMLElement.querySelector('select.mdc-select');

                var changeHandler = function (event) {
                    var changedSelect, selectToUpdate, value;

                    if (event.type === 'MDCSelect:change') {
                        changedSelect = custom;
                        selectToUpdate = native;
                        value = changedSelect.selectedOptions[0].id;
                    } else {
                        changedSelect = native;
                        selectToUpdate = custom;
                        value = changedSelect.value;
                    }

                    selectToUpdate.selectedIndex = changedSelect.selectedIndex;
                    UTIL.fire('acervo', 'updateFilter');
                };

                custom.listen('MDCSelect:change', changeHandler);
                native.addEventListener('change', changeHandler);
            });
        },

        activateMdcButtons: function () {
            var buttons = document.querySelectorAll('.mdc-button');

            if (!buttons.length) {
                return false;
            }

            UTIL.forEach(buttons, function (index, HTMLElement) {
                mdc.ripple.MDCRipple.attachTo(HTMLElement);
            });
        },

        activateMdcFields: function () {
            var inputs = document.querySelectorAll('.mdc-text-field');
            var inputsMdc = [];

            UTIL.forEach(inputs, function (index, HTMLElement) {
                inputsMdc[index] = new mdc.textfield.MDCTextField(HTMLElement);
            });

        },

        activateInputFiles: function () {

            var inputs = document.querySelectorAll( '.file-input' );
            var fileErrorClass = 'file-error';
            var fileSelectedClass = 'file-selected';

            UTIL.forEach(inputs, function (index, HTMLElement) {
                var label	 = HTMLElement.nextElementSibling,
                    labelVal = label.innerHTML;

                HTMLElement.addEventListener( 'change', function( e ) {
                    var inputfile = e.target;
                    var inputWrapper = inputfile.closest('.input-file-wrapper');
                    var fileData = inputfile.files[0];
                    var fileSize = parseFloat(fileData.size / 1024 / 1024).toFixed(2);

                    var maxFileSize = inputfile.dataset.maxsize;
                    var inputFilename = label.dataset.filenameinput ? document.getElementById(label.dataset.filenameinput) : label;

                    if ( FILES[inputfile.name] ) {
                        FILES[inputfile.name] = '';
                    }

                    inputFilename.innerHTML = fileData.name;

                    if (maxFileSize && fileSize > parseFloat(maxFileSize)) {
                        inputfile.value = "";
                        label.classList.remove(fileSelectedClass);
                        label.classList.add(fileErrorClass);
                        inputWrapper.classList.add(fileErrorClass);
                    } else {
                        var onComplete = inputfile.dataset.oncomplete ? inputfile.dataset.oncomplete : false;

                        if ( onComplete ) {
                            window[onComplete](inputfile, label);
                        } else {
                            label.classList.remove(fileErrorClass);
                            inputWrapper.classList.remove(fileErrorClass);
                            label.classList.add(fileSelectedClass);
                        }
                    }
                });
            });
        },

        fixedHeader: function () {
            var lastKnowY = 0;
            var ticking = false;
            var header = document.querySelector('.header');

            if ( !header ) {
                return;
            }

            var headerHeight = header.offsetHeight;
            // var isMobile = UTIL.isMobile();
            var isMobile = window.matchMedia("(max-width: 991px)").matches;

            function checkFixedHeader(scrollY) {
                if (scrollY > headerHeight) {
                    header.classList.add('sticky');
                } else  {
                    header.classList.remove('sticky');
                }
            }

            window.addEventListener('scroll', function(e) {
                lastKnowY = window.scrollY;
                if (!ticking) {
                    window.requestAnimationFrame(function() {
                        checkFixedHeader(lastKnowY);
                        ticking = false;
                    });
                }
                ticking = true;
            });
        },

        offcanvasMenu: function () {
            var navbarToggler = document.querySelector('.navbar-toggler');
            var navbarClose = document.querySelector('.btn-close-nav');
            var body = document.body;
            var siteWrapper = document.querySelector('.site-wrapper');
            var siteCanvas = document.querySelector('.site-canvas');
            var navIsActive = false;
            var navActiveClass = 'show-nav';

            if ( !navbarToggler ) {
                return;
            }

            navbarToggler.addEventListener('click', function () {
                if (navIsActive) {
                    body.classList.remove(navActiveClass);
                } else {
                    body.classList.add(navActiveClass);
                }
                navIsActive = !navIsActive;
            });

            navbarClose.addEventListener('click', function () {
                body.classList.remove(navActiveClass);
                navIsActive = false;
            });
        },

        searchBox: function () {
            var btnOpenSearch = document.querySelector('.btn-show-search');
            var btnCloseSearch = document.querySelector('.btn-close-search');
            var searchWrapper = document.querySelector('.search-wrapper');
            var searchInput = document.querySelector('.search-wrapper .form-control');

            if ( !btnOpenSearch ) {
                return;
            }

            btnOpenSearch.addEventListener('click', function () {
                searchWrapper.classList.add('is-open');
                searchInput.focus();
            });

            btnCloseSearch.addEventListener('click', function () {
                searchWrapper.classList.remove('is-open');
            });
        },

        enableLazyLoad: function () {
            var defaultClass = '.lazy';
            var elm = document.querySelector(defaultClass);

            if (LazyLoad !== undefined) {
                var ll = new LazyLoad({
                    elements_selector: defaultClass,
                    data_src: 'lazy-src',
                    data_srcset: 'lazy-srcset'
                });
            }
        }
    };

    UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        },

        forEach: function (array, callback, scope) {
            for (var i = 0; i < array.length; i++) {
                callback.call(scope, i, array[i]); // passes back stuff we need
            }
        },

        deviceOS: function () {
            var useragent = navigator.userAgent;

            if(useragent.match(/Android/i)) {
                return 'android';
            } else if(useragent.match(/webOS/i)) {
                return 'webos';
            } else if(useragent.match(/iPhone/i)) {
                return 'iphone';
            } else if(useragent.match(/iPod/i)) {
                return 'ipod';
            } else if(useragent.match(/iPad/i)) {
                return 'ipad';
            } else if(useragent.match(/Windows Phone/i)) {
                return 'windows phone';
            } else if(useragent.match(/SymbianOS/i)) {
                return 'symbian';
            } else if(useragent.match(/RIM/i) || useragent.match(/BB/i)) {
                return 'blackberry';
            } else {
                return false;
            }
        },

        isMobile: function () {
            return UTIL.deviceOS() !== false;
        }
    };

    Collection = {
        container: '',

        elements: [],

        template: '' +
            '<article class="entry article-featured">'+
                '<header>' +
                    '<div class="entry__image-wrapper">'+
                        '<a href="{permalink}">{thumbnail}</a>'+
                    '</div>'+
                    '<h1 class="entry-title"><a href="{permalink}">{post_title}</a></h1>'+
                '</header>' +
                '<div class="entry-content">' +
                    '<div class="entry-infos">' +
                        '{infos}' +
                    '</div>' +
                '</div>' +
            '</article>',

        templateInfos: '' +
                        '<div class="entry-info entry-info--{slug}">' +
                            '<span class="entry-info__label">{label}: </span>' +
                            '<span class="entry-info__value">{value}</span>' +
                        '</div>',

        filterData: {
            action: 'get_collection_posts',
            collection_nonce: wpglobal.collection_nonce
        },
        init: function () {
            Collection.container = document.querySelector('.js-ajax-response');
            Collection.elements = document.querySelectorAll('.filters select');
            Collection.getData();
        },

        updateFilter: function () {
            UTIL.forEach(Collection.elements, function (index, element) {
                Collection.filterData[element.dataset.filter] = element.value;
            });

            Collection.getData();
        },

        getData: function () {
            var request = $.post(wpglobal.ajax_url, Collection.filterData);

            request.done(Collection.getDataSuccess);
        },

        getDataSuccess: function (response) {
            if (response.length) {
                $('.js-ajax-empty-response').hide();
                Collection.parseData(response);
            } else {
                $('.js-ajax-response').html('');
                $('.js-ajax-empty-response').show();
            }
        },

        parseData: function (collectionData) {
            var itemHTML, itemInfoHTML = '';

            UTIL.forEach(collectionData, function (index, entry) {
                itemInfoHTML = '';

                UTIL.forEach(entry.infos, function (infoIndex, info) {
                    itemInfoHTML += Collection.templateInfos
                                        .replace('{slug}', info.slug)
                                        .replace('{label}', info.label)
                                        .replace('{value}', info.value);
                });

                itemHTML += Collection.template
                                .replace(/{permalink}/ig, entry.permalink)
                                .replace('{thumbnail}', entry.thumbnail_small)
                                .replace('{post_title}', entry.post_title)
                                .replace('{infos}', itemInfoHTML);
            });

            // TO-DO: descobrir porque existe um undefined no início da string itemHTML
            itemHTML = itemHTML.replace('undefined', '');

            console.log(itemHTML);

            $('.js-ajax-response').html(itemHTML);
        }


    };

    $(document).ready(UTIL.loadEvents);

})(jQuery, window.mdc, window.LazyLoad);
