(function() {
    var root = document.querySelector('.single-projetos');

    if (!root) {
        return;
    }

    var galleryWrapperSelector = '.entry-content';
    var galleryWrapper = document.querySelector(galleryWrapperSelector);
    var galleryItemSelector = '.lazy';
    var galleryItems = galleryWrapper.querySelectorAll(galleryItemSelector);
    var galleryItemsLength = galleryItems.length;
    var pswpElement = document.querySelectorAll('.pswp')[0];
    var items, options;

    var parseThumbnailElements = function(el) {
        var items = [],
            elm,
            item;

        for(var i = 0; i < galleryItemsLength; i++) {
            elm = galleryItems[i];

            // create slide object
            item = {
                src: elm.dataset.fullSrc,
                w: parseInt(elm.dataset.fullW, 10),
                h: parseInt(elm.dataset.fullH, 10),
                msrc: elm.getAttribute('src')
            };

            item.el = elm;
            items.push(item);
        }

        return items;
    };

    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault();

        var eTarget = e.target || e.srcElement;
        var index = eTarget.dataset.pswpUid;

        if(index >= 0) {
            options.index = index - 1;
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        }

        return false;
    };

    items = parseThumbnailElements(galleryWrapper);

    for(var i = 0, l = galleryItemsLength; i < l; i++) {
        galleryItems[i].setAttribute('data-pswp-uid', parseInt(i+1, 10));
        galleryItems[i].classList.add('pswp-img-' + parseInt(i+1, 10));
        // galleryItems[i].classList.add('pswp');
        galleryItems[i].onclick = onThumbnailsClick;
    }

    options = {

        getThumbBoundsFn: function(index) {
            index = parseInt(index, 10) + 1;

            var thumbnail = document.querySelector('.pswp-img-' + index),
                pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                rect = thumbnail.getBoundingClientRect();

            return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
        }
    };
})();
